import installNlPlugins from '@leverege/nimbelink-config-ui-plugin'
import Pairing from '@leverege/api-ui-pairing'

import TileRenderer from './plugins/tileRenderer/PluginSetup'
import FormValidators from './blueprint/forms/validators/PluginSetup'
import VinEditor from './actions/vin/VinEditor'

const install = ( molten ) => {
  Pairing.install( molten )
  TileRenderer.install( molten )
  installNlPlugins( molten, 'tracker', 'Tracker' )

  FormValidators.install( molten )
  
  molten.addPlugin( 'BlueprintAttributeFormEditor', {
    type : 'vin',
    editor : VinEditor
  } )

}

export default {
  install
}
