import { Config } from '@leverege/plugin';
import { Button, CompositeTextInput, Form, Label, Pane } from '@leverege/ui-elements';
import React, { useContext, useEffect, useState } from 'react';
import { ApiProvider } from '@leverege/api-redux';
import { LoadingView } from '@leverege/molten/views';
import Scanner from './Scanner';
import { ignoreNonVINCharacterEvent, isVIN, normalizeVIN, removeNonVINCharacters } from './VinUtils';

import S from './VinEditor.css';

const imageOpts = {
  style : {
    width : '16px',
    height : '16px',
  },
};

function VinEditor( props ) {
  const [ loading, setLoading ] = useState( false );
  const [ scanning, setScanning ] = useState( false );
  const networkId = Config.get( 'VehicleNetwork', 'id' );
  const aliasKey = Config.get( 'VehicleNetwork', 'aliasKey' );
  const api = useContext( ApiProvider.Context )
  const { validator = {} } = useContext( Form.Context )

  useEffect( () => {
    const { pairing, attr } = props;
    const name = attr?.blueprint?.updatePath;
    const value = getValue();

    validator.onInputChange( {
      data : 'header/networkId',
      value : networkId,
    } );

    if ( value && pairing ) {
      onChange( {
        data : name,
        value,
      } );
    }
  }, [] );

  const getValue = () => {
    const { attr } = props;
    const name = attr?.blueprint?.updatePath;

    return validator.value( name );
  };

  const onImage = ( { value, data } ) => {
    const code = Array.isArray( value ) ? value[0].text : value.text;

    if ( !isVIN( code ) ) {
      return;
    }

    onChange( {
      value : normalizeVIN( code ),
      data,
    } );

    toggleScanner();
  };

  const onScan = ( { value, data } ) => {
    const { codeResult : { code } } = value;

    if ( !isVIN( code ) ) {
      return;
    }

    onChange( {
      value : normalizeVIN( code ),
      data,
    } );

    toggleScanner();
  };

  const toggleScanner = () => {
    setScanning( prevState => !prevState );
  };

  const onChange = ( evt ) => {
    evt.value = removeNonVINCharacters( evt?.value?.toUpperCase?.() );
    const value = evt.value;

    validator.onInputChange( {
      data : evt.data,
      value,
    } );

    validator.onInputChange( {
      data : `header/networkAliases/${networkId}/${aliasKey}`,
      value,
    } );

    decodeVIN( value, true );
  };

  const setDecoded = ( decoded, force ) => {
    const data = validator.getData().values;

    Object.entries( decoded ).forEach( ( [ key, value ] ) => {
      const updateKey = `data/${key}`;
      if ( updateKey in data && ( data[updateKey] == null || force ) ) {
        if ( key === 'vin' ) {
          return;
        }

        if ( key !== 'year' ) {
          validator.onInputChange( {
            data : updateKey,
            value : value.trim(),
          } );
        } else {
          validator.onInputChange( {
            data : updateKey,
            value : parseInt( value ),
          } );
        }
      }
    } );
  };

  const setError = ( err ) => {
    const { attr } = props;
    const data = validator.getData();

    const name = attr?.blueprint?.updatePath;

    validator.applyErrorState( data, name, err );
  };

  const decodeVIN = async ( vin, force = false ) => {
    if ( !vin ) {
      return;
    }

    setLoading( true );

    const projectId = api.options.projectId;

    try {
      const res = await api.project( projectId ).srv( `vin/${vin} ` ).get()
      setDecoded( res.result, force );
    } catch ( err ) {
      console.error( err );
      let error = 'Error decoding VIN, please verify that the VIN you have entered is correct.';

      if ( err.status === 404 ) {
        error = 'VIN not found. The entered VIN could not be decoded. Please enter VIN details manually.';
      }

      setError( error );
    } finally {
      setLoading( false );
    }
  };

  const { attr } = props;
  const name = attr?.blueprint?.updatePath;

  return (
    <Pane layout="flex:colS">
      <Label variant="inputTitle">VIN</Label>
      <CompositeTextInput
        className={S.input}
        eventData={name}
        value={getValue()}
        onChange={onChange}
        onKeyDown={ignoreNonVINCharacterEvent}
        post={(
          <Button
            icon="https://recovr-ui-assets.storage.googleapis.com/camera.png"
            imageOpts={imageOpts}
            onClick={toggleScanner}
            variant="pairCamera|tertiarySmallIconic"/>
        )}/>
      <Form.Error variant="inputCaptionSmallError" name={name} />
      { loading && <LoadingView title="VIN Processing"/> }
      <Scanner
        eventData={name}
        open={scanning}
        onScan={onScan}
        onImage={onImage}
        onClose={toggleScanner}/>
    </Pane>
  );
}

export default VinEditor 
