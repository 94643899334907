const match = ( plugin ) => {
  const { id } = plugin
  const targetLinks = [ 'ResourceUsers', 'SystemUsers' ]
  return id && id.startsWith( 'link' ) && targetLinks.some( d => id.endsWith( d ) )
}

export default function userNavLinkMutator( type, plugin, molten ) {
  if ( type !== 'Action' ) {
    return plugin
  }

  if ( !( match( plugin ) ) ) {
    return plugin
  }

  const appearance = ( opts ) => {
    const toReturn = plugin.appearance( opts )
    return { ...toReturn }
  }
  return { ...plugin, appearance }
}
