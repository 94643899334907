import Model from './Model'

function getOpacity( model ) {
  const show = model ? Model.getShowAtZoom( model ) : null
  const hide = model ? Model.getHideAtZoom( model ) : null
  const fade = model ? Model.getFadeZoom( model ) || 0 : null

  let opacity = 1
  if ( fade > 0.001 ) {
    if ( typeof show === 'number' ) {
      if ( typeof hide === 'number' ) {
        if ( hide > show ) {
          opacity = [
            'interpolate',
            [ 'exponential', 0.5 ],
            [ 'zoom' ],
            show - fade, 0,
            show, 1,
            hide, 1,
            hide + fade, 0 ]
        }
      } else {
        opacity = [ 'interpolate', [ 'exponential', 0.5 ], [ 'zoom' ], show - fade, 0, show, 1 ]
      }
    } else if ( typeof hide === 'number' ) {
      opacity = [ 'interpolate', [ 'exponential', 0.5 ], [ 'zoom' ], 1, hide, 1, hide + fade, 0 ]
    }
  } else if ( typeof show === 'number' ) {
    if ( typeof hide === 'number' ) {
      opacity = [ 'step', [ 'zoom' ], 0, show, 1, hide, 0 ]
    } else {
      opacity = [ 'step', [ 'zoom' ], 0, show, 1 ]
    }
  } else if ( typeof hide === 'number' ) {
    opacity = [ 'step', [ 'zoom' ], 1, hide, 0 ]
  }
  return opacity
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getOpacity
}
