import React from 'react'
import { PuffLoader as Loader } from 'react-spinners'
import { ItemModel } from '@leverege/ui-attributes'
import { GlobalState } from '@leverege/ui-redux'
import Theme from './theme'
import ActionPersonaMutator from './mutators/ActionPersonaMutator'
import userNavLinkMutator from './mutators/UserNavLinkMutator'
// import LoadingView from '../src/views/LoadingView'

function create( env = process.env ) {
  return {
    uiName : process.env.userManagementUiName || env.UI_NAME || process.env.UI_NAME,
    baseUrl : null,
    personaUrl : '/p/:persona',
    molten : {
      splashClass : Loading,
    },
    theme : {
      theme : Theme,
      appearanceProjectId : env.APPEARANCE_PROJECT_ID || process.env.APPEARANCE_PROJECT_ID || '3A48uiQ626esPO2PcnwmaN', // CG: '4EVFMnwW8etUUycNKl6bvJ',
      useActiveTheme : true
    },
    api : {
      host : process.env.IMAGINE_HOST || 'http://localhost:8181',
      systemId : env.SYSTEM_ID || process.env.SYSTEM_ID,
      projectId : env.PROJECT_ID || process.env.PROJECT_ID,
      storeToken : true
    },
    maps : {
      apiKey : process.env.GOOGLE_MAPS_API_KEY || env.GOOGLE_MAPS_API_KEY,
      mapId : process.env.GOOGLE_MAPS_MAP_ID || env.GOOGLE_MAPS_MAP_ID
    },
    googleAnalytics : { 
      trackerId : 'G-XLWE87MLHT',
    },
    DetailTypes : [
      // {
      //   model : ItemModel,
      //   settingsPath : 'listModel',
      //   actionName : 'List',
      //   routePath : '/settings/list',
      //   icon : 'fa fa-list fa-fw'
      // },
      // {
      //   model : ItemModel,
      //   settingsPath : 'mobileListModel',
      //   actionName : 'Mobile List',
      //   routePath : '/settings/mobileList',
      //   icon : 'fa fa-list fa-fw'
      // },
      {
        model : ItemModel,
        settingsPath : 'associationModel',
        actionName : 'Association',
        routePath : '/settings/association',
        icon : 'fa fa-plug fa-fw'
      } 
    ],
    plugins : {
      // LoadConfig will use these and call Config.set()
      config : {
        GroupScreen : {
          defaultPerPage : 200
        },
        BlueprintRoutes : {
          baseUrl : '/p/:persona'
        },
        BetaSwitch : {
          enabled : false,
          betaHost : 'recovr-moltenv2.web.app',
          normalHost : 'localhost:8454',
          feedbackLink : 'https://airtable.com/shrP9Hizjjl5bSabx',
          releaseNotesLink : 'https://releases.recovr.biz/release-notes/internal-support-beta-2-0-0'
        },
        // InactivityTimer : {
        //   maxInactiveTime : 10000
        // },
        Intercom : {
          appId : env.INTERCOM_APP_ID || process.env.INTERCOM_APP_ID
        },
        ItemScreen : { 
          collapsable : true, 
          collapseLabel : 'Collapse',
          collapseOpenIcon : 'fa fa-chevron-right',
          collapseCloseIcon : 'fa fa-chevron-left'
        },
        UserSettings : {
          // delegate : <delegate class>
        },
        ResponsiveDataViewer : {
          minWidth : 430
        },
        ResponsiveUtility : {
          smallBreakpoint : 555
        },
        Main : {
          // userIcon : 'fa fa-star fa-fh'
        },
        LoginScreen : {
          // userText : 'Account Email'
        },
        AccountScreen : {
          redirectUrl : '/account/profile'
        },
        LoadingScreen : {
          spinner : Loader,
          props : { size : 70 } // error in lib -this one is off center
        },
        LoadingView : {
          spinner : Loader,
          props : { size : 30 } // error in lib -this one is off center
        },
        LostScreen : {
          image : [
            'https://storage.googleapis.com/molten-ui-assets/404-UFO.png',
            'https://storage.googleapis.com/molten-ui-assets/404-Earth.png',
            'https://storage.googleapis.com/molten-ui-assets/404-Page.png',
            'https://storage.googleapis.com/molten-ui-assets/404-Ghost.png' ]
        },
        LayoutViewerSelector : {
          allowEditing : true // 'Installer, UserManager, default'
        },

        mapBoxPosition : {
          center : [ -77.1902656, 39.1081595 ],
          zoom : 18,
          selectedRezoomThreshold : 12, // if we re beyond this range, selection will cause rezoom/center
          selectedZoom : 18, // this is the target range to go to on selection. 
          pathOptions : {
            building : { selectedRezoomThreshold : 10, selectedZoom : 18 }
          }
        },
        'blueprint.building.ItemScreen' : {
          // redirectUrl : {
          //   from : '/p/:persona/building/:buildingId',
          //   to : '/p/:persona/building/:buildingId/zones'
          // }
        },
        'blueprint.location.ItemScreen' : {
          // showNavBar : false
        },
        ResourceUsers : {
          // screenIcon : 'https://storage.googleapis.com/nimbelink-ui-assets/titleIcons/user-title.svg'
        },
        SystemUsers : {
          // screenIcon : 'https://storage.googleapis.com/nimbelink-ui-assets/titleIcons/user-title.svg'
        },
        // AttributeInlineEditor : {
        //   inProgress : LoadingView.Inline
        // }
      },
     
      excludes : [
        // 'account.route.Contact',
        // 'account.link.Contact'
        // turn off simple search analysis
        // 'molten.BlueprintRouteCreator.SimpleSearch'
      ],
      mutators : [
        // array of functions( type, plugin ) that can return the plugin to continue,
        // null to exclude it, or a plugin to replace it.
        ActionPersonaMutator,
        userNavLinkMutator
      ]
    },
    actions : {
      'list.SettingsAction' : {
        handles : ( context ) => {
          // hide for all but steve on mobile
          const { clientProps : { mobile } } = context
          // only steve when on mobile
          console.log( GlobalState.get()?.ui?.activePersona?.name )
          return GlobalState.get()?.ui?.activePersona?.name === 'default' || !mobile
        }
      },
      'table.SettingsAction' : {
        handles : ( context ) => {
          // hide for all but steve on mobile
          const { clientProps : { mobile } } = context
          // only steve when on mobile
          console.log( GlobalState.get()?.ui?.activePersona?.name )
          return GlobalState.get()?.ui?.activePersona?.name === 'default' || !mobile
        }
      },
      'nimbelink.link.updateConfig' : {
        handles : ( ) => {
          // hide for all but steve on mobile
          // only steve when on mobile
          const persona = GlobalState.get()?.ui?.activePersona?.name
          console.log( persona )
          return persona === 'default' || persona === 'companyAdmin' || persona === 'nlAdmin'
        }
      },
      'nimbelink.link.updateConfigItem' : {
        handles : ( ) => {
          // hide for all but steve on mobile
          // only steve when on mobile
          const persona = GlobalState.get()?.ui?.activePersona?.name
          console.log( persona )
          return persona === 'default' || persona === 'companyAdmin' || persona === 'nlAdmin'
        }
      },
      'molten.dataViewers.list.EditSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.table.EditSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.screens.group.search.SearchSettings' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.details.EditDetailsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.layout.layoutAction' : {
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.map.EditSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.history.EditSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.aggregations.ContainerEditSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.aggregations.EditSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.layout.DefaultLayoutAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.layout.DefaultLayoutAction.a' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.layout.actionBar' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.dataViewers.layout.SaveAsLayoutAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'search.EditSearchSettingsActionMobile' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'search.EditSearchSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'molten.mapDataViewer.EditSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'link.desktop.Settings' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'link.mobile.Settings' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'dataCard.SettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'dashboard.ToggleEditingAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'aggregations.SettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
      'layout.AdvancedSettingsAction' : { 
        handles : () => {
          const persona = GlobalState.get()?.ui?.activePersona?.name
          return persona === 'default'
        }
      },
    }
  }
}

function Loading( props ) {
  return React.createElement( 'div', null, 'Loading Molten...' )
}
export default {
  create
}
