export default function actionPersonaMutator( type, plugin, molten ) {
  if ( type !== 'Action' ) {
    return plugin
  }
  const { id } = plugin
  const mutate = molten.getConfig( 'actions' )?.[id]
  if ( mutate ) {
    return { 
      ...plugin,
      ...mutate
    }
  }
  return plugin
}
