import { isVIN } from '../../../actions/vin/VinUtils'

export default {
  install( molten ) {

    molten.addPlugin( 'BlueprintAttributeFormValidator', {
      type : 'vin',
      validator : attr => ( {
        active : true,
        submitValidator : ( v, ...rest ) => {
          if ( typeof v !== 'string' || !isVIN( v ) ) {
            return `${v} is not a valid VIN.`
          }
        
          return true
        }
      } )
    } )

  }
}
