// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vTable_QUDBO {
  font: normal 16px/20px var( --vf-Inter );
  color: #394756;
  border: 1px solid var( --color-base100 );
}
.vTable_QUDBO .header_uiW1y {
  text-align: left;
}
.vTable_QUDBO .row__jVCj {
  font: normal 16px/20px var( --vf-Inter );
  color: #394756;
  border-top: 1px solid #e8ecf0;
  border-left: none;
  border-right: none;
}
.vTable_QUDBO .row__jVCj.even_d36AL {
  background-color: #f9fafb;
}
.vTable_QUDBO .headerCell_NX4M1 {
  padding: var( --size-pad12 ) var( --size-padLarge );
}
.vTable_QUDBO .bodyCell_tQ_LD {
  padding: var( --size-pad12 ) var( --size-padLarge );
}`, "",{"version":3,"sources":["webpack://./src/theme/VTable.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,cAAc;EACd,wCAAwC;AAC1C;AACA;EACE,gBAAgB;AAClB;AACA;EACE,wCAAwC;EACxC,cAAc;EACd,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,mDAAmD;AACrD;AACA;EACE,mDAAmD;AACrD","sourcesContent":[".vTable {\n  font: normal 16px/20px var( --vf-Inter );\n  color: #394756;\n  border: 1px solid var( --color-base100 );\n}\n.vTable .header {\n  text-align: left;\n}\n.vTable .row {\n  font: normal 16px/20px var( --vf-Inter );\n  color: #394756;\n  border-top: 1px solid #e8ecf0;\n  border-left: none;\n  border-right: none;\n}\n.vTable .row.even {\n  background-color: #f9fafb;\n}\n.vTable .headerCell {\n  padding: var( --size-pad12 ) var( --size-padLarge );\n}\n.vTable .bodyCell {\n  padding: var( --size-pad12 ) var( --size-padLarge );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vTable": `vTable_QUDBO`,
	"header": `header_uiW1y`,
	"row": `row__jVCj`,
	"even": `even_d36AL`,
	"headerCell": `headerCell_NX4M1`,
	"bodyCell": `bodyCell_tQ_LD`
};
export default ___CSS_LOADER_EXPORT___;
