import React from 'react'
import { Relationship } from '@leverege/molten/imagine'

import Model from './Model'
import ItemTileRenderer from './ItemTileRenderer'

const TAG = 'has:tiles'

const findRelationship = ( rel ) => {
  if ( rel.attribute == null && rel.isItem && rel.blueprint.hasTag( TAG ) ) {
    return rel
  }
  let pr = rel.parentRelationship
  while ( pr ) {
    if ( pr.blueprint.hasTag( TAG ) ) {
      return pr
    }
    pr = pr.parentRelationship
  }
  return null
}

function processRelationship( molten, relationship, options ) {
  const { path } = relationship
  const tgRel = findRelationship( relationship )
  if ( tgRel == null ) {
    return
  }
  const { blueprint } = tgRel
  const layerName = `${blueprint.type}.tileGroup`
  const plg = {
    id : `nimbelink.${path}.${blueprint.type}.ItemTileRenderer`,
    matches : { client : 'MapDataViewer', path },
    layerName,
    sort : 'zzzzzz',

    createModel( lastModel ) {
      if ( lastModel == null || lastModel.type !== Model.TYPE || lastModel.objectType !== blueprint.type ) {
        return Model.create( layerName, `${blueprint.name} Tiles`, blueprint.type )
      }
      return lastModel
    },
    create : ( props ) => {
      const { match } = props
      const { actions, objRef } = Relationship.getObjectRefAndAction( tgRel, match )
      return React.createElement( ItemTileRenderer, {
        ...props,
        objRef,
        actions,
        rel : tgRel,
        key : `${path}:${layerName}` } )
    }
  }
  molten.addPlugin( 'MapBoxRenderer', plg )
}

export default {
  id : 'nimbelink.ItemTileRendererCreator',
  processRelationship
}
