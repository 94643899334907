// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_WuqWh {
  color: var( --color-base800 );
  border: 1px solid var( --color-base100 );
  font-size: var( --size-bodyMedium );
  box-shadow: 0 8px 16px var( --color-shadowBase ),0 0px 1px var( --color-shadowBase );
  border-radius: 4px;
  background-color: var( --color-white );
}
.popup_WuqWh > .popupItem_itOrI {
  font: normal 15px/24px var( --vf-Inter );
  color: var( --color-base700 );
  width: 100%;
  padding: var( --size-padMedium ) var( --size-padLarge );
  display: block;
  min-width: 160px;
  text-align: left;
  min-height: 40px;
}
.popup_WuqWh > .popupItem_itOrI:hover {
  background-color: var( --color-base050 );
}
.popup_WuqWh > .popupItem_itOrI:active {
  color: var( --color-brand500 );
}
.popup_WuqWh > .popupItem_itOrI[disabled] {
  color: var( --color-base300 );
}
.popup_WuqWh > .popupDivider_j6zVe {
  height: 1px;
  background-color: var( --color-base100 );
}
.popup_WuqWh.popup-mainScreenMode_lxS51 {
  padding: var( --size-padSmall );
  margin-top: var( --size-padSmall );
}
.popup_WuqWh.popup-mainScreenMode_lxS51 > .popupItem_itOrI {
  color: var( --color-base300 );
  font-size: var( --size-header4 );
  min-width: 0px;
  border-radius: var( --size-small );
}
.popup_WuqWh.popup-mainScreenMode_lxS51 > .popupItem_itOrI:hover {
  color: var( --color-brand500 );
  background-color: var( --color-brand100 );
}
.popup_WuqWh.popup-mainScreenMode_lxS51 > .popupItem_itOrI:active {
  color: var( --color-brand500 );
}`, "",{"version":3,"sources":["webpack://./src/theme/Popup.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,wCAAwC;EACxC,mCAAmC;EACnC,oFAAoF;EACpF,kBAAkB;EAClB,sCAAsC;AACxC;AACA;EACE,wCAAwC;EACxC,6BAA6B;EAC7B,WAAW;EACX,uDAAuD;EACvD,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,8BAA8B;AAChC;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,WAAW;EACX,wCAAwC;AAC1C;AACA;EACE,+BAA+B;EAC/B,kCAAkC;AACpC;AACA;EACE,6BAA6B;EAC7B,gCAAgC;EAChC,cAAc;EACd,kCAAkC;AACpC;AACA;EACE,8BAA8B;EAC9B,yCAAyC;AAC3C;AACA;EACE,8BAA8B;AAChC","sourcesContent":[".popup {\n  color: var( --color-base800 );\n  border: 1px solid var( --color-base100 );\n  font-size: var( --size-bodyMedium );\n  box-shadow: 0 8px 16px var( --color-shadowBase ),0 0px 1px var( --color-shadowBase );\n  border-radius: 4px;\n  background-color: var( --color-white );\n}\n.popup > .popupItem {\n  font: normal 15px/24px var( --vf-Inter );\n  color: var( --color-base700 );\n  width: 100%;\n  padding: var( --size-padMedium ) var( --size-padLarge );\n  display: block;\n  min-width: 160px;\n  text-align: left;\n  min-height: 40px;\n}\n.popup > .popupItem:hover {\n  background-color: var( --color-base050 );\n}\n.popup > .popupItem:active {\n  color: var( --color-brand500 );\n}\n.popup > .popupItem[disabled] {\n  color: var( --color-base300 );\n}\n.popup > .popupDivider {\n  height: 1px;\n  background-color: var( --color-base100 );\n}\n.popup.popup-mainScreenMode {\n  padding: var( --size-padSmall );\n  margin-top: var( --size-padSmall );\n}\n.popup.popup-mainScreenMode > .popupItem {\n  color: var( --color-base300 );\n  font-size: var( --size-header4 );\n  min-width: 0px;\n  border-radius: var( --size-small );\n}\n.popup.popup-mainScreenMode > .popupItem:hover {\n  color: var( --color-brand500 );\n  background-color: var( --color-brand100 );\n}\n.popup.popup-mainScreenMode > .popupItem:active {\n  color: var( --color-brand500 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `popup_WuqWh`,
	"popupItem": `popupItem_itOrI`,
	"popupDivider": `popupDivider_j6zVe`,
	"popup-mainScreenMode": `popup-mainScreenMode_lxS51`
};
export default ___CSS_LOADER_EXPORT___;
