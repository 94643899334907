const VIN_REGEX = /^[0-9A-Za-z]{17}$/

const NON_VIN_CHARACTERS_REGEX = /[^0-9A-HJ-Za-hj-z]/i

export function normalizeVIN( code ) {
  return code.replace( /^I/i, '' )
}

export function isVIN( code ) {
  return VIN_REGEX.test( normalizeVIN( code ) )
}

export function ignoreNonVINCharacterEvent( evt ) {
  if ( isInvalidVINCharacter( evt.key ) ) {
    evt.preventDefault()
  }
}

export function isInvalidVINCharacter( char ) {
  return NON_VIN_CHARACTERS_REGEX.test( char )
}

export function removeNonVINCharacters( value ) {
  if ( !value ) {
    return ''
  }
  return value.replace( NON_VIN_CHARACTERS_REGEX, '' )
}
