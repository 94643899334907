// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list_lF6gl > .listItem_DMHLA {
  color: var( --color-base700 );
  border: 1px solid transparent;
  padding: 8px var( --size-padLarge );
  font-size: var( --size-fontBodySmall );
  font-weight: 600;
  background-color: var( --color-white );
}
.list_lF6gl > .listItem_DMHLA[data-list-item-selected=true] {
  background-color: var( --color-brand100 );
}
.list_lF6gl > .listItem_DMHLA[data-disabled] {
  color: var( --color-base500 );
  border: 1px solid var( --color-base200 );
  background-color: var( --color-base050 );
}`, "",{"version":3,"sources":["webpack://./src/theme/List.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,6BAA6B;EAC7B,mCAAmC;EACnC,sCAAsC;EACtC,gBAAgB;EAChB,sCAAsC;AACxC;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,6BAA6B;EAC7B,wCAAwC;EACxC,wCAAwC;AAC1C","sourcesContent":[".list > .listItem {\n  color: var( --color-base700 );\n  border: 1px solid transparent;\n  padding: 8px var( --size-padLarge );\n  font-size: var( --size-fontBodySmall );\n  font-weight: 600;\n  background-color: var( --color-white );\n}\n.list > .listItem[data-list-item-selected=true] {\n  background-color: var( --color-brand100 );\n}\n.list > .listItem[data-disabled] {\n  color: var( --color-base500 );\n  border: 1px solid var( --color-base200 );\n  background-color: var( --color-base050 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `list_lF6gl`,
	"listItem": `listItem_DMHLA`
};
export default ___CSS_LOADER_EXPORT___;
