import { UseResponsiveBreakpoints } from '@leverege/molten/views'
import { Button, Flex, Pane, PortalOverlay, Text } from '@leverege/ui-elements'
import { CodeInput, QuaggaScanner } from '@leverege/ui-scanner-elements'
import React, { useCallback, useState } from 'react'
import S from './Scanner.css'

const zxOpts = new Map()

function Scanner( props ) {
  const { open, eventData, onClose, onScan, onImage, mobile } = props

  const [ showError, setShowError ] = useState( false )

  const onError = useCallback( ( evt ) => {
    setShowError( true )
  } )

  const onTryAgain = useCallback( () => {
    setShowError( false )
  } )

  const onCancel = useCallback( () => {
    onClose()
  }, [ setShowError ] )

  return (
    <PortalOverlay
      position="center"
      show={open}
      closeOnEscape={false}
      closeOnDocumentClick={false}
      onClose={onClose}>
      <Pane
        variant={mobile ? 'mobileItemForm' : null}
        className={S.scannerContainer}>
        <Button
          icon="fa fa-times fa-fw"
          onClick={onClose}
          className={S.scannerClose}
          variant="secondaryIconic" />
        <QuaggaScanner
          scanning={open}
          readers={[
            'code_39_reader',
            'code_39_vin_reader',
            'code_128_reader'
          ]}
          eventData={eventData}
          onChange={onScan} />
        <CodeInput
          zxOpts={zxOpts}
          variant="upload"
          onChange={onImage}
          onError={onError}
          eventData={eventData}
          className={S.scannerUpload}>Upload
        </CodeInput>
        {showError && (
          <NotFoundDialog
            onTryAgain={onTryAgain}
            onCancel={onCancel} />
        )}
      </Pane>
    </PortalOverlay>
  )
}

function NotFoundDialog( props ) {
  const { onTryAgain, onCancel } = props
  // eslint-disable-next-line
  const message = 'No barcode could be detected in the uploaded image. Please try again with a different image or enter the code manually'

  return (
    <Pane {...props} className={S.notFoundDialog}>
      <Flex
        className={S.notFoundFlex}
        variant="colL"
        align="center"
        justify="center">
        <img
          width={120}
          height={120}
          alt="No Device"
          src="https://recovr-ui-assets.storage.googleapis.com/no_device.png" />
        <Text variant="formTitle">Barcode Not Found</Text>
        <Text variant="bodyCentered">
          {message}
        </Text>
        <Button
          onClick={onTryAgain}
          className={S.fullWidth}
          variant="primary">Try Again
        </Button>
        <Button
          className={S.fullWidth}
          variant="link"
          eventData={{
            screen : 'main',
            field : null
          }}
          onClick={onCancel}>Cancel
        </Button>
      </Flex>
    </Pane>
  )
}

function ScannerMobile( props ) {
  return <Scanner {...props} mobile />
}

function ScannerDesktop( props ) {
  return <Scanner {...props} mobile={false} />
}

const components = {
  small : ScannerMobile,
  big : ScannerDesktop
}

export default function ScannerResponsive( props ) {
  return UseResponsiveBreakpoints( components, props )
}
