import React from 'react'
import { GlobalState } from '@leverege/ui-redux'
import { Layer, Source } from 'react-mapbox-gl'

import Model from './Model'
import { getOpacity } from './TileUtil'

const noFade = { 'raster-fade-duration' : 0 }
const LAYOUT_VISIBLE = { visibility : 'visible' }
// const LAYOUT_INVISIBLE = { visibility : 'none' }
// const PAINT_INVISIBLE = { ...noFade, 'raster-opacity' : 0.0 }
const EMPTY_ARRAY = Object.freeze( [] )

export default function ItemTileRenderer( opts ) {
  const { objRef, model, order } = opts
  const obj = GlobalState.get()?.models?.lookup?.[objRef?.id]
  if ( !obj || !Model.getVisible( model ) ) {
    return EMPTY_ARRAY
  }

  const mapLayers = obj?.metadata?.mapLayers
  if ( !mapLayers ) return EMPTY_ARRAY

  const opacity = getOpacity( model )

  const sid = `${obj.id}-tile`
  const rtn = []
  mapLayers.forEach( ( layer, index ) => {
    const lid = `${sid}-${index}`
    const { source } = layer
    const paint = { ...noFade, 'raster-opacity' : opacity }
    const layout = LAYOUT_VISIBLE
    rtn.push(
      React.createElement( Source, { key : sid, id : sid, tileJsonSource : source } )
    )
    rtn.push( (
      <Layer key={lid}
        id={lid}
        type="raster"
        sourceId={sid}
        before={`normal-${order}`}
        paint={paint}
        layout={layout} /> ) )
  } )

  return rtn
}
