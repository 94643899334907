// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs_gHBqO > .link_IZlRc {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-base300 );
  padding: 0px;
  outline: none;
  display: block;
  transition: background-color var( --time-medium );
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.breadcrumbs_gHBqO > .link_IZlRc:hover {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-linkNormal );
}
.breadcrumbs_gHBqO > .link_IZlRc:focus {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-base300 );
}
.breadcrumbs_gHBqO > .link_IZlRc:active {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-linkNormal );
}
.breadcrumbs_gHBqO > .separator_WqqHG {
  color: var( --color-base300 );
  padding: var( --size-padSmall );
  font-size: 16px;
}
.breadcrumbs_gHBqO > .current_D1mtA {
  font: normal 15px/24px var( --vf-Inter );
  color: var( --color-base500 );
}`, "",{"version":3,"sources":["webpack://./src/theme/Breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,cAAc;EACd,iDAAiD;EACjD,6BAA6B;EAC7B,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;AAC1B;AACA;EACE,kDAAkD;EAClD,gCAAgC;AAClC;AACA;EACE,kDAAkD;EAClD,6BAA6B;AAC/B;AACA;EACE,kDAAkD;EAClD,gCAAgC;AAClC;AACA;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,eAAe;AACjB;AACA;EACE,wCAAwC;EACxC,6BAA6B;AAC/B","sourcesContent":[".breadcrumbs > .link {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-base300 );\n  padding: 0px;\n  outline: none;\n  display: block;\n  transition: background-color var( --time-medium );\n  background-color: transparent;\n  border-top-width: 0px;\n  border-left-width: 0px;\n  border-right-width: 0px;\n  border-bottom-width: 0px;\n}\n.breadcrumbs > .link:hover {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-linkNormal );\n}\n.breadcrumbs > .link:focus {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-base300 );\n}\n.breadcrumbs > .link:active {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-linkNormal );\n}\n.breadcrumbs > .separator {\n  color: var( --color-base300 );\n  padding: var( --size-padSmall );\n  font-size: 16px;\n}\n.breadcrumbs > .current {\n  font: normal 15px/24px var( --vf-Inter );\n  color: var( --color-base500 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `breadcrumbs_gHBqO`,
	"link": `link_IZlRc`,
	"separator": `separator_WqqHG`,
	"current": `current_D1mtA`
};
export default ___CSS_LOADER_EXPORT___;
