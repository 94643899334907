import Molten from '@leverege/molten'
import Constants from './Constants'
import Config from './Config'
import PluginSetup from './PluginSetup'

import './global-style.css'

function start( opts = { } ) {

  if ( 'serviceWorker' in navigator ) {
    window.addEventListener( 'load', () => {
      navigator.serviceWorker
        .register( '/service-worker.js' )
        .then( ( registration ) => {
          console.log( 'SW registered: ', registration )
        } )
        .catch( ( err ) => {
          console.error( 'SW registration failed: ', err )
        } )
    } )
  }

  const cfg = Config.create()

  Molten.init( cfg )
  
  // Add plugins
  PluginSetup.install( Molten )

  Molten.create( )

}

window.Application = { start }

document.addEventListener( 'DOMContentLoaded', start )
