import React from 'react'
import { useValueChange } from '@leverege/ui-hooks'
import { Pane, Checkbox } from '@leverege/ui-elements'

import Model from './Model'

function GeoLayerEditor( props ) {

  const { value } = props
  const onModelChange = useValueChange( Model, props )

  return (
    <Pane variant="editor">
      <Checkbox
        // containerClassName={S.fill}
        label="Visible"
        eventData="setVisible"
        value={Model.getVisible( value )}
        onChange={onModelChange} />
    </Pane>
  )
}

export default React.memo( GeoLayerEditor )
