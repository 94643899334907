import Model from './Model'
import Editor from './Editor'
import ItemTileRendererCreator from './ItemTileRendererCreator'

const install = ( molten ) => {
  molten.addPlugin( 'Model', { type : Model.TYPE, model : Model } )
  // molten.addPlugin( 'ModelEditor', { type : Model.TYPE, editor : GeoLayerEditor } )
  molten.addPlugin( 'ModelEditor', { type : Model.TYPE, editor : Editor } )
  molten.addPlugin( 'Molten.blueprintRelationshipProcessor', ItemTileRendererCreator )
}

const toExport = { install }
export default toExport
