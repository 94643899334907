// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle_lrnhJ {
  width: 36px;
  height: 24px;
  outline: none;
}
.toggle_lrnhJ .background_OJiho {
  height: 100%;
  border-radius: 24px;
  background-color: var( --color-base200 );
}
.toggle_lrnhJ:focus .background_OJiho {
  background-color: var( --color-base300 );
}
.toggle_lrnhJ:hover .background_OJiho {
  background-color: var( --color-base300 );
}
.toggle_lrnhJ[data-disabled=true] .background_OJiho {
  background-color: var( --color-base100 );
}
.toggle_lrnhJ:active .background_OJiho {
  background-color: var( --color-base200 );
}
.toggle_lrnhJ[data-value=true] .background_OJiho {
  background-color: var( --color-brand500 );
}
.toggle_lrnhJ:focus[data-value=true] .background_OJiho {
  background-color: var( --color-brand700 );
}
.toggle_lrnhJ:hover[data-value=true] .background_OJiho {
  background-color: var( --color-brand700 );
}
.toggle_lrnhJ[data-disabled=true][data-value=true] .background_OJiho {
  background-color: var( --color-base100 );
}
.toggle_lrnhJ:active[data-value=true] .background_OJiho {
  background-color: var( --color-brand500 );
}
.toggle_lrnhJ .handle_cc_xF {
  width: 16px;
  margin: 4px 2px 2px;
  height: 16px;
  outline: none;
  border-radius: 50%;
  background-color: var( --color-white );
}
.toggle_lrnhJ[data-disabled=true] .handle_cc_xF {
  background-color: var( --color-base300 );
}
.toggle_lrnhJ[data-value=true] .handle_cc_xF {
  transform: translate( 15px, 0 );
}
.toggle_lrnhJ[data-disabled=true][data-value=true] .handle_cc_xF {
  background-color: var( --color-base300 );
}`, "",{"version":3,"sources":["webpack://./src/theme/Toggle.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;AACf;AACA;EACE,YAAY;EACZ,mBAAmB;EACnB,wCAAwC;AAC1C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,sCAAsC;AACxC;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,+BAA+B;AACjC;AACA;EACE,wCAAwC;AAC1C","sourcesContent":[".toggle {\n  width: 36px;\n  height: 24px;\n  outline: none;\n}\n.toggle .background {\n  height: 100%;\n  border-radius: 24px;\n  background-color: var( --color-base200 );\n}\n.toggle:focus .background {\n  background-color: var( --color-base300 );\n}\n.toggle:hover .background {\n  background-color: var( --color-base300 );\n}\n.toggle[data-disabled=true] .background {\n  background-color: var( --color-base100 );\n}\n.toggle:active .background {\n  background-color: var( --color-base200 );\n}\n.toggle[data-value=true] .background {\n  background-color: var( --color-brand500 );\n}\n.toggle:focus[data-value=true] .background {\n  background-color: var( --color-brand700 );\n}\n.toggle:hover[data-value=true] .background {\n  background-color: var( --color-brand700 );\n}\n.toggle[data-disabled=true][data-value=true] .background {\n  background-color: var( --color-base100 );\n}\n.toggle:active[data-value=true] .background {\n  background-color: var( --color-brand500 );\n}\n.toggle .handle {\n  width: 16px;\n  margin: 4px 2px 2px;\n  height: 16px;\n  outline: none;\n  border-radius: 50%;\n  background-color: var( --color-white );\n}\n.toggle[data-disabled=true] .handle {\n  background-color: var( --color-base300 );\n}\n.toggle[data-value=true] .handle {\n  transform: translate( 15px, 0 );\n}\n.toggle[data-disabled=true][data-value=true] .handle {\n  background-color: var( --color-base300 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `toggle_lrnhJ`,
	"background": `background_OJiho`,
	"handle": `handle_cc_xF`
};
export default ___CSS_LOADER_EXPORT___;
