// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_Bcix_ {
  font: normal 16px/26px var( --vf-Inter );
  color: var( --color-base500 );
}
.label_Bcix_.label-inputTitle_UUZ5d {
  font: 600 13px/20px var( --vf-Inter );
  color: var( --color-base700 );
}
.label_Bcix_.label-inputCaption_pNvRG {
  font: 13px/20px var( --vf-Inter );
  color: #394756;
}`, "",{"version":3,"sources":["webpack://./src/theme/Label.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,6BAA6B;AAC/B;AACA;EACE,qCAAqC;EACrC,6BAA6B;AAC/B;AACA;EACE,iCAAiC;EACjC,cAAc;AAChB","sourcesContent":[".label {\n  font: normal 16px/26px var( --vf-Inter );\n  color: var( --color-base500 );\n}\n.label.label-inputTitle {\n  font: 600 13px/20px var( --vf-Inter );\n  color: var( --color-base700 );\n}\n.label.label-inputCaption {\n  font: 13px/20px var( --vf-Inter );\n  color: #394756;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label_Bcix_`,
	"label-inputTitle": `label-inputTitle_UUZ5d`,
	"label-inputCaption": `label-inputCaption_pNvRG`
};
export default ___CSS_LOADER_EXPORT___;
