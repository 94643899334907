import ModelUtil from '@leverege/model-util'

import { GeoLayerModel } from '@leverege/molten/data-viewers'

const TYPE = 'nimbelink.mapRenderer.TileLayer'

/**
 * This represents geo tile group that has geo tiles
 */
function create( layerName, name, objectType ) {
  return {
    ...GeoLayerModel.create( layerName, name, objectType ),

    type : TYPE,
    name : name || 'Unknown Layer',
    visible : false,
    layerName,
    objectType
  }
}

export default {
  ...GeoLayerModel,

  TYPE,
  create,
  getObjectType : m => m.objectType,
  ...ModelUtil.createAllValue( 'visible' ),
  ...ModelUtil.createAllValue( 'nonInteractableAtZoom' ),
}
