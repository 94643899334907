module.exports = {
  "hierarchy": {
    "overlay": [
      "pane-overlay"
    ],
    "navHeader": [
      "pane-navHeader"
    ],
    "loginPane": [
      "pane-loginPane"
    ],
    "contentSection": [
      "pane-contentSection"
    ],
    "contentBorder": [
      "pane-contentSection",
      "pane-contentBorder"
    ],
    "formHeader": [
      "pane-formHeader"
    ],
    "formBody": [
      "pane-formBody"
    ],
    "formButtons": [
      "pane-formHeader",
      "pane-formButtons"
    ],
    "dialogHeader": [
      "pane-dialogHeader"
    ],
    "dialogBody": [
      "pane-dialogBody"
    ],
    "dialogFooter": [
      "pane-dialogFooter"
    ],
    "dialog": [
      "pane-dialog"
    ],
    "loginWarning": [
      "pane-loginWarning"
    ],
    "formGeoshape": [
      "pane-formGeoshape"
    ],
    "contentSectionWhite": [
      "pane-contentSection",
      "pane-contentSectionWhite"
    ],
    "card": [
      "pane-card"
    ],
    "mobileTabPortal": [
      "pane-mobileTabPortal"
    ],
    "mobileActions": [
      "pane-mobileActions"
    ],
    "mobileTabsHeader": [
      "pane-mobileTabsHeader"
    ],
    "mobileList": [
      "pane-mobileList"
    ],
    "mobileMapToggle": [
      "pane-mobileMapToggle"
    ],
    "mobileItemHeader": [
      "pane-mobileItemHeader"
    ],
    "mobileItemBody": [
      "pane-mobileItemBody"
    ],
    "mobileListSettings": [
      "pane-mobileList",
      "pane-mobileListSettings"
    ],
    "mobileDtrHeader": [
      "pane-mobileTabsHeader",
      "pane-mobileDtrHeader"
    ],
    "mobileDtrContent": [
      "pane-mobileDtrContent"
    ],
    "sidebar": [
      "pane-sidebar"
    ],
    "tablePagination": [
      "pane-tablePagination"
    ],
    "editPageSizeOverlay": [
      "pane-editPageSizeOverlay"
    ],
    "rootHeader": [
      "pane-rootHeader"
    ],
    "dataCard": [
      "pane-dataCard"
    ],
    "graphContainer": [
      "pane-card",
      "pane-graphContainer"
    ],
    "detail": [
      "pane-dialog",
      "pane-detail"
    ],
    "detailHeader": [
      "pane-dialogHeader",
      "pane-detailHeader"
    ],
    "detailContent": [
      "pane-detailContent"
    ],
    "detailFooter": [
      "pane-detailFooter"
    ],
    "collectionEditorListSection": [
      "pane-collectionEditorListSection"
    ],
    "collectionEditorListHeader": [
      "pane-collectionEditorListHeader"
    ],
    "collectionEditorListContent": [
      "pane-collectionEditorListContent"
    ],
    "collectionEditorListFooter": [
      "pane-collectionEditorListFooter"
    ],
    "collectionEditorSelectionSection": [
      "pane-collectionEditorSelectionSection"
    ],
    "collectionEditorSelectionHeader": [
      "pane-collectionEditorSelectionHeader"
    ],
    "collectionEditorSelectionContent": [
      "pane-collectionEditorSelectionContent"
    ],
    "collectionEditorSelectionFooter": [
      "pane-collectionEditorSelectionFooter"
    ],
    "dialogContent": [
      "pane-dialogBody",
      "pane-dialogContent"
    ],
    "rootEditorContent": [
      "pane-rootEditorContent"
    ],
    "editorDialog": [
      "pane-editorDialog"
    ],
    "editor": [
      "pane-editor"
    ]
  },
  "props": {
    "default": {},
    "overlay": {},
    "navHeader": {},
    "loginPane": {},
    "contentSection": {},
    "contentBorder": {},
    "formHeader": {},
    "formBody": {},
    "formButtons": {},
    "dialogHeader": {},
    "dialogBody": {},
    "dialogFooter": {},
    "dialog": {},
    "loginWarning": {},
    "formGeoshape": {},
    "contentSectionWhite": {},
    "card": {},
    "mobileTabPortal": {},
    "mobileActions": {},
    "mobileTabsHeader": {},
    "mobileList": {},
    "mobileMapToggle": {},
    "mobileItemHeader": {},
    "mobileItemBody": {},
    "mobileListSettings": {},
    "mobileDtrHeader": {},
    "mobileDtrContent": {},
    "sidebar": {},
    "tablePagination": {},
    "editPageSizeOverlay": {},
    "rootHeader": {},
    "dataCard": {},
    "graphContainer": {},
    "detail": {},
    "detailHeader": {},
    "detailContent": {},
    "detailFooter": {},
    "collectionEditorListSection": {},
    "collectionEditorListHeader": {},
    "collectionEditorListContent": {},
    "collectionEditorListFooter": {},
    "collectionEditorSelectionSection": {},
    "collectionEditorSelectionHeader": {},
    "collectionEditorSelectionContent": {},
    "collectionEditorSelectionFooter": {},
    "dialogContent": {},
    "rootEditorContent": {},
    "editorDialog": {},
    "editor": {}
  }
}