// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/@leverege/ui-elements/lib/ui-elements.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/@leverege/ui-elements/lib/theme/default.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/mapbox-gl/dist/mapbox-gl.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin : 0;
  padding : 0;
}

#root {
  position: absolute;
  margin : 0;
  padding : 0;
  top : 0;
  left : 0;
  right : 0;
  bottom : 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/global-style.css"],"names":[],"mappings":"AAIA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,OAAO;EACP,QAAQ;EACR,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["@import '../node_modules/@leverege/ui-elements/lib/ui-elements.css';\n@import '../node_modules/@leverege/ui-elements/lib/theme/default.css';\n@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';\n\nbody {\n  margin : 0;\n  padding : 0;\n}\n\n#root {\n  position: absolute;\n  margin : 0;\n  padding : 0;\n  top : 0;\n  left : 0;\n  right : 0;\n  bottom : 0;\n}\n\n*, *:before, *:after {\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
