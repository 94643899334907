// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scanner_uQ9is {
    z-index: 1;
}

.scannerContainer_nHOMw { 
    z-index : 100;
    background-color : var( --color-white ); 
    overflow: hidden !important;
    min-height: 208px;
    height: 100%;
    position: relative;
    min-width:400px;
    max-height: 60vh;
}

.scannerClose_QKfrQ { 
    position : absolute;
    top : 8px;
    right : 8px;
    color : var( --color-white );
    z-index: 1;
  }
    
  .scannerUpload_qk8bV { 
    position : absolute;
    bottom : 8px;
    left : 8px;
  }

  .notFoundDialog_z_Svi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 2;
  }

  .notFoundFlex_ZPAKI {
    height: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/actions/vin/Scanner.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uCAAuC;IACvC,2BAA2B;IAC3B,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,SAAS;IACT,WAAW;IACX,4BAA4B;IAC5B,UAAU;EACZ;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,mBAAmB;IACnB,UAAU;EACZ;;EAEA;IACE,YAAY;EACd","sourcesContent":[".scanner {\n    z-index: 1;\n}\n\n.scannerContainer { \n    z-index : 100;\n    background-color : var( --color-white ); \n    overflow: hidden !important;\n    min-height: 208px;\n    height: 100%;\n    position: relative;\n    min-width:400px;\n    max-height: 60vh;\n}\n\n.scannerClose { \n    position : absolute;\n    top : 8px;\n    right : 8px;\n    color : var( --color-white );\n    z-index: 1;\n  }\n    \n  .scannerUpload { \n    position : absolute;\n    bottom : 8px;\n    left : 8px;\n  }\n\n  .notFoundDialog {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: #ffffff;\n    z-index: 2;\n  }\n\n  .notFoundFlex {\n    height: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scanner": `scanner_uQ9is`,
	"scannerContainer": `scannerContainer_nHOMw`,
	"scannerClose": `scannerClose_QKfrQ`,
	"scannerUpload": `scannerUpload_qk8bV`,
	"notFoundDialog": `notFoundDialog_z_Svi`,
	"notFoundFlex": `notFoundFlex_ZPAKI`
};
export default ___CSS_LOADER_EXPORT___;
